<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
        }
    },
}
</script>

<template>
    <div class="row">
        <div class="col-12">
            <b-button variant="primary">
                {{title}}
                <i class="ri-arrow-right-line align-middle ml-2"></i>
            </b-button>
        </div>
    </div>
</template>
