export const toastUtils = {
    success,
    warning,
    error
};

function success(bvToast, title, content) {
    bvToast.toast(content, {
        title: title,
        variant: "success",
        solid: true
    });
}

function warning(bvToast, title, content) {
    bvToast.toast(content, {
        title: title,
        variant: "warning",
        solid: true
    });
}

function error(bvToast, title, content) {
    bvToast.toast(content, {
        title: title,
        variant: "danger",
        solid: true
    });
}